import {
  ColumnDirective,
  ColumnsDirective,
  CommandClickEventArgs,
  CommandColumn,
  Filter,
  FilterSettingsModel,
  GridComponent,
  Inject,
  Resize
} from '@syncfusion/ej2-react-grids';
import { createRef, useMemo } from 'react';

import AlertModal, { AlertModalRefProps } from '../../../../components/common/AlertModal';
import Select, { OptionsType } from '../../../../components/common/Select';
import Toast from '../../../../components/common/Toast';
import { deleteChargesData } from '../../../../services/charges';
import { ChargesType } from '../../../../types/Charges';
import { changeGridType } from '../../../../utils/grid';
import { commandsDelete, commandsEdit, editOptions, settings } from '../../../AdminPanel/config';
import useChargesContext from '../hooks/useCharges.hook';

import styles from './chargesList.module.css';
import BackButton from '../../../../components/common/BackButton/BackButton';

function ChargesList() {
  const {
    toastRef,
    modalRef,
    chargesList,
    setChargesList,
    selectedRow,
    setSelectedRow,
    chargesFullArray,
    gridRefreshRef,
    resetGrid
  } = useChargesContext();

  const alertModalRef = createRef<AlertModalRefProps>();
  const gridRef = createRef<GridComponent>();

  const stateOptions: OptionsType[] | undefined = useMemo(
    () =>
      chargesFullArray?.reduce((options: OptionsType[], option) => {
        if (options.findIndex((value) => value.value === option.state) === -1) {
          options.push({ id: Number(option.id), value: option.state });
        }
        return options;
      }, []),
    [chargesFullArray]
  );

  const handleFilterClick = (value: OptionsType) => {
    const selectedValue = value.value;
    const filteredArray = chargesFullArray.filter(function (data) {
      return data.state === selectedValue;
    });
    setChargesList(filteredArray);
    resetGrid();
  };

  const clickEditorDeleteCharges = (args: CommandClickEventArgs) => {
    if (args.commandColumn && args.rowData) {
      setSelectedRow(args.rowData as ChargesType);

      if (args.commandColumn.type === 'Edit') {
        modalRef.current?.showModal();
      } else if (args.commandColumn.type === 'Delete') {
        alertModalRef.current?.showConfirmBox();
      }
    }
  };

  // *********************************Delete ChargesData
  const onClickYes = async () => {
    alertModalRef.current?.hideConfirmBox();

    const removeResponse = await deleteChargesData(Number(selectedRow.id));
    if (removeResponse) {
      const filteredData = chargesList.filter((value) => value.id !== selectedRow.id);
      setChargesList(filteredData);
      setSelectedRow({} as ChargesType);
    } else {
      setChargesList(chargesList);
    }
    resetGrid();
  };

  const onClickNo = () => {
    alertModalRef.current?.hideConfirmBox();

    setSelectedRow({} as ChargesType);
  };
  // *********************************Delete ChargesData
  const FilterOptions: FilterSettingsModel = {
    type: 'Excel'
  };
  return (
    <>
      <Toast ref={toastRef} />
      <AlertModal.Container ref={alertModalRef}>
        <AlertModal.Icon iconClassName="" />
        <AlertModal.Content content="Want to delete?" />
        <AlertModal.Buttons>
          <AlertModal.Button name="Yes" filled onClick={onClickYes} />
          <AlertModal.Button name="No" onClick={onClickNo} />
        </AlertModal.Buttons>
      </AlertModal.Container>
      <div className={styles.state_main}>
        <div className="row">
          <div className="col-md-2">
            <div className={styles.state_main}>
              <Select
                name="State"
                label="State"
                options={stateOptions || []}
                placeholder="Select State"
                defaultValue=""
                type="single"
                disabled={!stateOptions}
                onOptionClick={(value) => handleFilterClick(value)}
              />
            </div>
          </div>
          <div className="col-md-6"></div>
          <div className="col-md-2">
            <BackButton />
          </div>
          <div className="col-md-2">
            <div className={'styles.add_maindepth'}>
              <button
                className={`${styles.button_label} ${styles.buttoncolor}`}
                onClick={() => {
                  setSelectedRow({} as ChargesType);
                  modalRef.current?.showModal();
                }}
              >
                Create
              </button>
            </div>
          </div>
        </div>
        <div key={gridRefreshRef} className="gjhg" id="grid">
          <GridComponent
            ref={gridRef}
            // dataSource={changeGridType(chargesList)}
            dataSource={chargesList || []}
            selectionSettings={settings}
            editSettings={editOptions}
            commandClick={clickEditorDeleteCharges}
            allowResizing={true}
            allowFiltering={true}
            filterSettings={FilterOptions}
            width="100%"
            gridLines="Both"
          >
            <ColumnsDirective>
              <ColumnDirective field="chargesIncluded" headerText="Charges Included" width="40" textAlign="Center" />
              <ColumnDirective field="state" headerText="State" width="40" textAlign="Center" />
              <ColumnDirective field="type" headerText="Type" width="40" textAlign="Center" />
              <ColumnDirective field="taxforCharges" headerText="Tax for Charges" width="40" textAlign="Center" />
              <ColumnDirective
                headerText="Edit"
                width="10"
                commands={commandsEdit}
                textAlign="Center"
                background-color="#000"
              />
              <ColumnDirective headerText="Delete" width="10" commands={commandsDelete} textAlign="Center" />
            </ColumnsDirective>
            <Inject services={[CommandColumn, Resize, Filter]} />
          </GridComponent>
        </div>
      </div>
    </>
  );
}

export default ChargesList;
