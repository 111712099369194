import { Document, Page, StyleSheet, Text } from '@react-pdf/renderer';
import { SERVICETYPE } from '../constants/servicetype';
import { RulesQuestions } from '../data/Rules';

const styles = StyleSheet.create({
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35
  },
  title: {
    fontSize: 24,
    textAlign: 'center',
    marginBottom: 20
  },
  result: {
    fontSize: 18,
    textAlign: 'center',
    marginBottom: 20
  },
  text: {
    margin: 12,
    fontSize: 14,
    textAlign: 'justify'
  },
  image: {
    marginVertical: 15,
    marginHorizontal: 100
  },
  question: {
    fontSize: 12,
    marginBottom: 20,
    textAlign: 'left',
    color: 'black',
    fontWeight: 'bold'
  },
  answer: {
    fontSize: 12,
    marginBottom: 20,
    textAlign: 'left',
    color: 'grey'
  },
  pageNumber: {
    position: 'absolute',
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: 'center',
    color: 'grey'
  }
});

interface PDFPropsType {
  state: string;
  city: string;
  zip: string;
  address?: string;
  estimateType: string;
  performedFor?: string;
  work: string;
  contractType: string;
  contractorType: string;
  soldToCustomer: string;
  customerType: string;
  exmptionCertificate?: string;
  acceptable_Certificates?: string;
  bHierarchyServAgreement?: string;
  bHierarchyProj?: string;
  scope?: string;
  bHierarchyQS?: string;
  servAgreementScope?: string;
  project_Type?: string;
  gcK_Code?: string;
  order_Type?: string;
  material_Number?: string;
  taxability_Guidance?: string;
  charges: Array<string>;
  serviceType?: any;
}

function PDFfile(props: PDFPropsType) {
  const {
    state,
    city,
    zip,
    address,
    estimateType,
    performedFor,
    contractType,
    contractorType,
    soldToCustomer,
    work,
    customerType,
    exmptionCertificate,
    acceptable_Certificates,
    bHierarchyServAgreement,
    bHierarchyProj,
    scope,
    bHierarchyQS,
    gcK_Code,
    project_Type,
    order_Type,
    material_Number,
    taxability_Guidance,
    charges,
    serviceType
  } = props;
  return (
    <Document>
      <Page style={styles.body}>
        <Text style={styles.title}> TAXABILITY DECISION TOOL </Text>
        {props.state && (
          <>
            <Text style={styles.question}>1.{RulesQuestions.state}</Text>
            <Text style={styles.answer}>{state}</Text>
          </>
        )}
        {props.city && (
          <>
            <Text style={styles.question}>2.{RulesQuestions.city}</Text>
            <Text style={styles.answer}>{city}</Text>
          </>
        )}
        {props.zip && (
          <>
            <Text style={styles.question}>3.{RulesQuestions.zip}</Text>
            <Text style={styles.answer}>{zip}</Text>
          </>
        )}
        {props.address && (
          <>
            <Text style={styles.question}>4.{RulesQuestions.address}</Text>
            <Text style={styles.answer}>{address}</Text>
          </>
        )}
        {props.estimateType && (
          <>
            <Text style={styles.question}>5. {RulesQuestions.estimateType}</Text>
            <Text style={styles.answer}>{estimateType}</Text>
          </>
        )}
        {state === 'Texas' || state === 'Iowa' || state === 'North Carolina' || state === 'Kansas' ? (
          <>
            {props.performedFor && (
              <>
                <Text style={styles.question}>6. {RulesQuestions.performedFor}</Text>
                <Text style={styles.answer}>{performedFor}</Text>
              </>
            )}
            {props.work && (
              <>
                <Text style={styles.question}>7. {RulesQuestions.work}</Text>
                <Text style={styles.answer}>{work}</Text>
              </>
            )}

            {props.contractType && (
              <>
                <Text style={styles.question}>8. {RulesQuestions.contractType}</Text>
                <Text style={styles.answer}>{contractType}</Text>
              </>
            )}

            {props.contractorType && (
              <>
                <Text style={styles.question}>9. {RulesQuestions.contractorType}</Text>
                <Text style={styles.answer}>{contractorType}</Text>
              </>
            )}

            {props.soldToCustomer && (
              <>
                <Text style={styles.question}>10. {RulesQuestions.soldToCustomer}</Text>
                <Text style={styles.answer}>{soldToCustomer}</Text>
              </>
            )}

            {props.customerType && (
              <>
                <Text style={styles.question}>11. {RulesQuestions.customerType}</Text>
                <Text style={styles.answer}>{customerType}</Text>
              </>
            )}

            {props.exmptionCertificate && (
              <>
                <Text style={styles.question}>12. {RulesQuestions.excemptionCertificate}</Text>
                <Text style={styles.answer}>{exmptionCertificate}</Text>
              </>
            )}

            {props.exmptionCertificate && (
              <>
                <Text style={styles.question}> Acceptable Ceritificate </Text>
                <Text style={styles.answer}>{acceptable_Certificates}</Text>
              </>
            )}
            {props.estimateType && estimateType === 'Project' && bHierarchyProj && (
              <>
                <Text style={styles.question}>13. {RulesQuestions.bHierarchyProj}</Text>
                <Text style={styles.answer}>
                  {bHierarchyProj} - {scope}
                </Text>
              </>
            )}
          </>
        ) : estimateType === SERVICETYPE.serviceAgreement ? (
          <>
            {props.work && (
              <>
                <Text style={styles.question}>6. {RulesQuestions.work}</Text>
                <Text style={styles.answer}>{work}</Text>
              </>
            )}

            {props.contractType && (
              <>
                <Text style={styles.question}>7. {RulesQuestions.contractType}</Text>
                <Text style={styles.answer}>{contractType}</Text>
              </>
            )}

            {props.contractorType && (
              <>
                <Text style={styles.question}>8. {RulesQuestions.contractorType}</Text>
                <Text style={styles.answer}>{contractorType}</Text>
              </>
            )}

            {props.customerType && (
              <>
                <Text style={styles.question}>9. {RulesQuestions.customerType}</Text>
                <Text style={styles.answer}>{customerType}</Text>
              </>
            )}

            {props.exmptionCertificate && (
              <>
                <Text style={styles.question}>10. {RulesQuestions.excemptionCertificate}</Text>
                <Text style={styles.answer}>{exmptionCertificate}</Text>
              </>
            )}

            {props.exmptionCertificate && (
              <>
                <Text style={styles.question}> Acceptable Ceritificate </Text>
                <Text style={styles.answer}>{acceptable_Certificates}</Text>
              </>
            )}
          </>
        ) : (
          <>
            {props.work && (
              <>
                <Text style={styles.question}>6. {RulesQuestions.work}</Text>
                <Text style={styles.answer}>{work}</Text>
              </>
            )}

            {props.contractType && (
              <>
                <Text style={styles.question}>7. {RulesQuestions.contractType}</Text>
                <Text style={styles.answer}>{contractType}</Text>
              </>
            )}

            {props.contractorType && (
              <>
                <Text style={styles.question}>8. {RulesQuestions.contractorType}</Text>
                <Text style={styles.answer}>{contractorType}</Text>
              </>
            )}

            {props.soldToCustomer && (
              <>
                <Text style={styles.question}>9. {RulesQuestions.soldToCustomer}</Text>
                <Text style={styles.answer}>{soldToCustomer}</Text>
              </>
            )}

            {props.customerType && (
              <>
                <Text style={styles.question}>10. {RulesQuestions.customerType}</Text>
                <Text style={styles.answer}>{customerType}</Text>
              </>
            )}

            {props.exmptionCertificate && (
              <>
                <Text style={styles.question}>11. {RulesQuestions.excemptionCertificate}</Text>
                <Text style={styles.answer}>{exmptionCertificate}</Text>
              </>
            )}

            {props.exmptionCertificate && (
              <>
                <Text style={styles.question}> Acceptable Ceritificate </Text>
                <Text style={styles.answer}>{acceptable_Certificates}</Text>
              </>
            )}
          </>
        )}

        {estimateType && estimateType === 'ServiceAgreement' && bHierarchyServAgreement && (
          <>
            <Text style={styles.question}>11. {RulesQuestions.bHierarchyServAgreement}?</Text>
            <Text style={styles.answer}>{bHierarchyServAgreement}</Text>
          </>
        )}
        {/* {props.selectedRules['estimateType'] &&
          props.selectedRules['estimateType'] === 'Project' &&
          props.selectedRules['serviceType'] && (
            <>
              <Text style={styles.question}>8. {rawData[9]['questions']}?</Text>
              <Text style={styles.answer}>{props.selectedRules['serviceType'].toString()}</Text>
            </>
          )} */}
        {props.estimateType &&
          estimateType === 'Project' &&
          bHierarchyProj &&
          state !== 'Texas' &&
          state !== 'Iowa' &&
          state !== 'North Carolina' &&
          state !== 'Kansas' && (
            <>
              <Text style={styles.question}>12. {RulesQuestions.bHierarchyProj}</Text>
              <Text style={styles.answer}>
                {bHierarchyProj} - {scope}
              </Text>
            </>
          )}

        {estimateType && estimateType === 'Quoted Service' && bHierarchyQS && (
          <>
            <Text style={styles.question}>12. {RulesQuestions.bHierarchyQS}</Text>
            <Text style={styles.answer}>
              {props.bHierarchyQS}- {props.servAgreementScope}{' '}
            </Text>
          </>
        )}

        <>
          <Text style={styles.result}>Results</Text>
        </>

        {(project_Type || gcK_Code || material_Number || taxability_Guidance || order_Type) && (
          <>
            <>
              <Text style={styles.answer}> Project Type: {project_Type} </Text>
              {/* <Text style={styles.answer}>{props.project_Type}</Text> */}
            </>

            <>
              <Text style={styles.answer}> GCK_Code: {gcK_Code}</Text>
              {/* <Text style={styles.answer}>{props.gcK_Code}</Text> */}
            </>
            {order_Type && (
              <>
                <Text style={styles.answer}> Order_Type: {order_Type}</Text>
                {/* <Text style={styles.answer}>{props.gcK_Code}</Text> */}
              </>
            )}

            <>
              <Text style={styles.answer}> Material_Number: {material_Number}</Text>
              {/* <Text style={styles.answer}>{props.material_Number}</Text> */}
            </>
            <>
              <Text style={styles.answer}> Taxability_Guidance: {taxability_Guidance}</Text>
              {/* <Text style={styles.answer}>{props.taxability_Guidance}</Text> */}
            </>
            <>
              <Text style={styles.question}> Charges </Text>
              {charges.map((value: string) => (
                <Text style={styles.answer}>{value}</Text>
              ))}
            </>
            <>
              {(estimateType && estimateType === 'Quoted Service') ||
                (estimateType && estimateType === 'Project' && (
                  <>
                    <Text style={styles.question}>Service Type</Text>
                    {serviceType.map((value: string) => (
                      <Text style={styles.answer}>{value}</Text>
                    ))}
                  </>
                ))}
            </>
          </>
        )}
        <Text
          style={styles.pageNumber}
          render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`}
          fixed
        />
      </Page>
    </Document>
  );
}

export default PDFfile;
