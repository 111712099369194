import {
  ColumnDirective,
  ColumnsDirective,
  CommandClickEventArgs,
  CommandColumn,
  Filter,
  FilterSettingsModel,
  Grid,
  GridComponent,
  Inject,
  Resize
} from '@syncfusion/ej2-react-grids';
import { createRef, useMemo } from 'react';

import AlertModal, { AlertModalRefProps } from '../../../../components/common/AlertModal';
import Select, { OptionsType } from '../../../../components/common/Select';
import Toast from '../../../../components/common/Toast';
import { deleteServiceMapping } from '../../../../services/ServiceMappings';
import { MappingsType } from '../../../../types/Mappings';
import { changeGridType } from '../../../../utils/grid';
import { commandsDelete, commandsEdit, editOptions, settings } from '../../config';
import useServiceMappingContext from '../hooks/useServiceMapping.hook';
import BackButton from '../../../../components/common/BackButton/BackButton';
import styles from './serviceMappingList.module.css';

function ServiceMappingList() {
  const {
    toastRef,
    modalRef,
    serviceMappingList,
    setServiceMappingList,
    selectedRow,
    setSelectedRow,
    mappingFullArray,
    gridRefreshRef,
    resetGrid
  } = useServiceMappingContext();

  const alertModalRef = createRef<AlertModalRefProps>();
  const gridRef = createRef<GridComponent>();

  const stateOptions: OptionsType[] | undefined = useMemo(
    () =>
      mappingFullArray?.reduce((options: OptionsType[], option) => {
        if (options.findIndex((value) => value.value === option.state) === -1) {
          options.push({ id: Number(option.id), value: option.state });
        }
        return options;
      }, []),
    [mappingFullArray]
  );

  const handleFilterClick = (value: OptionsType) => {
    const selectedValue = value.value;
    const filteredArray = mappingFullArray.filter(function (data) {
      return data.state === selectedValue;
    });
    setServiceMappingList(filteredArray);
    resetGrid();
  };

  const clickEditorDeleteMapping = (args: CommandClickEventArgs) => {
    if (args.commandColumn && args.rowData) {
      setSelectedRow(args.rowData as MappingsType);

      if (args.commandColumn.type === 'Edit') {
        modalRef.current?.showModal();
      } else if (args.commandColumn.type === 'Delete') {
        alertModalRef.current?.showConfirmBox();
      }
    }
  };

  const onClickYes = async () => {
    alertModalRef.current?.hideConfirmBox();

    const removeResponse = await deleteServiceMapping(Number(selectedRow.id));
    if (removeResponse) {
      const filteredData = serviceMappingList.filter((value) => value.id !== selectedRow.id);
      setServiceMappingList(filteredData);
      setSelectedRow({} as MappingsType);
    } else {
      setServiceMappingList(serviceMappingList);
    }
    resetGrid();
  };

  const onClickNo = () => {
    alertModalRef.current?.hideConfirmBox();

    setSelectedRow({} as MappingsType);
  };

  const FilterOptions: FilterSettingsModel = {
    type: 'Excel'
  };

  return (
    <>
      <Toast ref={toastRef} />
      <AlertModal.Container ref={alertModalRef}>
        <AlertModal.Icon iconClassName="" />
        <AlertModal.Content content="Want to delete?" />
        <AlertModal.Buttons>
          <AlertModal.Button name="Yes" filled onClick={onClickYes} />
          <AlertModal.Button name="No" onClick={onClickNo} />
        </AlertModal.Buttons>
      </AlertModal.Container>

      <div className={styles.state_main}>
        <div className="row">
          <div className="col-md-2">
            <div className={styles.state_main}>
              <Select
                name="State"
                label="State"
                options={stateOptions || []}
                placeholder="Select State"
                defaultValue=""
                type="single"
                disabled={!stateOptions}
                onOptionClick={(value) => handleFilterClick(value)}
              />
            </div>
          </div>
          <div className="col-md-6"></div>
          <div className="col-md-2">
            <BackButton />
          </div>
          <div className="col-md-2">
            <div className={styles.add_maindepth}>
              <button
                className={`${styles.button_label} ${styles.buttoncolor}`}
                onClick={() => {
                  setSelectedRow({} as MappingsType);
                  modalRef.current?.showModal();
                }}
              >
                Create
              </button>
            </div>
          </div>
        </div>
        <div key={gridRefreshRef} id="grid">
          <GridComponent
            ref={gridRef}
            // dataSource={changeGridType(serviceMappingList)}
            dataSource={serviceMappingList || []}
            selectionSettings={settings}
            editSettings={editOptions}
            commandClick={clickEditorDeleteMapping}
            allowResizing={true}
            allowFiltering={true}
            filterSettings={FilterOptions}
            width="100%"
            gridLines="Both"
          >
            <Inject services={[CommandColumn, Resize, Filter]} />
            <ColumnsDirective>
              <ColumnDirective field="projectType" headerText="Project Type" width="200" textAlign="Center" />
              <ColumnDirective field="gckCode" headerText="GCK Code" width="200" textAlign="Center" />
              <ColumnDirective
                field="businessHierarchy"
                headerText="Business Hierarchy"
                width="200"
                textAlign="Center"
              />
              <ColumnDirective field="materialNumber" headerText="Material Number" width="200" textAlign="Center" />
              <ColumnDirective
                field="materialDescription"
                headerText="Material Description"
                width="200"
                textAlign="Center"
              />
              <ColumnDirective field="productCode" headerText="Product Code" width="200" textAlign="Center" />
              <ColumnDirective field="state" headerText="State" width="200" textAlign="Center" />
              <ColumnDirective
                field="taxabilityGuidance"
                headerText="Taxability Guidance"
                width="200"
                textAlign="Center"
              />
              <ColumnDirective field="materialTaxation" headerText="Material Taxation" width="200" textAlign="Center" />
              <ColumnDirective headerText="Edit" width="80" commands={commandsEdit} textAlign="Center" />
              <ColumnDirective headerText="Delete" width="80" commands={commandsDelete} textAlign="Center" />
            </ColumnsDirective>
          </GridComponent>
        </div>
      </div>
    </>
  );
}

export default ServiceMappingList;
