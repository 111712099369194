import {
  RuleFieldsConfigType,
  RuleFieldsIDType,
  RuleFieldsOptionType,
  RuleFieldsQuestionType
} from '../types/RulesType';

export const RulesId: RuleFieldsIDType = {
  state: 1,
  city: 2,
  zip: 3,
  estimateType: 4,
  performedFor: 5,
  projectType: 5 || 6,
  contractType: 6 || 7,
  contractorType: 7 || 8,
  soldToCustomer: 8,
  customerType: 9,
  excemptionCertificate: 10,
  bHierarchyServAgreement: 11,
  bHierarchyProj: 11,
  serviceType: 12,
  charge: 13
};

export const RulesQuestions: RuleFieldsQuestionType = {
  state: 'What state is the work being performed in?',
  city: 'Choose the City',
  zip: 'Choose the ZipCode',
  address: 'Enter the Street Address. (Optional)',
  estimateType: 'Is the estimate for a Service Agreement or a Project or a Quoted Service?',
  performedFor:
    'Is this the work being performed part of a New Construction, Additional Square Footage, or Existing structure?',
  work: 'Is this commercial or residential project?',
  contractType: 'What is the contract type?',
  contractorType: 'Are you acting as a Prime or Subcontractor?',
  soldToCustomer: 'Choose the Install Type',
  customerType: 'Who is our sold to customer?',
  excemptionCertificate: 'Have You Received The Exemption Certificate From The Sold To Customer?',
  bHierarchyServAgreement: 'What is the scope of work being performed?',
  bHierarchyProj: 'What is the scope of work being performed?',
  bHierarchyQS: 'What is the scope of work being performed?',
  serviceType: 'Services Included (choose all that applies)',
  servicesQS: 'Services Included (choose all that applies)',
  charge: 'Charges Included (choose all that applies)'
};

export const RulesOptions: RuleFieldsOptionType = {
  state: [],
  city: [],
  zip: [],
  estimateType: [],
  performedFor: [
    { id: 1, value: 'New Construction' },
    { id: 2, value: 'Additional Square Footage' },
    { id: 3, value: 'Existing structure' }
  ],
  work: [],
  contractType: [],
  contractorType: [],
  soldToCustomer: [
    { id: 1, value: 'Yes' },
    { id: 2, value: 'No' }
  ],
  customerType: [],
  excemptionCertificate: [
    { id: 1, value: 'Yes' },
    { id: 2, value: 'No' }
  ],
  bHierarchyServAgreement: [],
  ServAgreementScope: [],
  bHierarchyProj: [],
  scopeOfWork: [],
  bHierarchyQS: [],
  serviceType: [],
  servicesQS: [],
  chargeSA: [],
  chargeProj: [],
  installType: []
};

export const RulesConfig: RuleFieldsConfigType = {
  state: {
    iconNumber: 1,
    nextIconNumber: 2,
    dropdownView: 'single',
    isCompleted: false,
    showNext: false,
    showSubmit: false
  },
  city: {
    iconNumber: 2,
    nextIconNumber: 3,
    dropdownView: 'single',
    isCompleted: false,
    showNext: false,
    showSubmit: false
  },
  zip: {
    iconNumber: 3,
    nextIconNumber: 4,
    dropdownView: 'single',
    isCompleted: false,
    showNext: false,
    showSubmit: false
  },
  estimateType: {
    iconNumber: 4,
    nextIconNumber: 5,
    dropdownView: 'single',
    isCompleted: false,
    showNext: false,
    showSubmit: false
  },
  performedFor: {
    iconNumber: 5,
    nextIconNumber: 6,
    dropdownView: 'single',
    isCompleted: false,
    showNext: false,
    showSubmit: false
  },
  projectType: {
    iconNumber: 5 || 6,
    nextIconNumber: 6 || 7,
    dropdownView: 'single',
    isCompleted: false,
    showNext: false,
    showSubmit: false
  },
  contractType: {
    iconNumber: 6,
    nextIconNumber: 7,
    dropdownView: 'single',
    isCompleted: false,
    showNext: false,
    showSubmit: false
  },
  contractorType: {
    iconNumber: 7,
    nextIconNumber: 8,
    dropdownView: 'single',
    isCompleted: false,
    showNext: false,
    showSubmit: false
  },
  soldToCustomer: {
    iconNumber: 8,
    nextIconNumber: 9,
    dropdownView: 'single',
    isCompleted: false,
    showNext: false,
    showSubmit: false
  },
  customerType: {
    iconNumber: 9,
    nextIconNumber: 10,
    dropdownView: 'single',
    isCompleted: false,
    showNext: false,
    showSubmit: false
  },
  excemptionCertificate: {
    iconNumber: 10,
    nextIconNumber: 11,
    dropdownView: 'single',
    isCompleted: false,
    showNext: false,
    showSubmit: false
  },
  bHierarchyServAgreement: {
    iconNumber: 11,
    nextIconNumber: 12,
    dropdownView: 'single',
    isCompleted: false,
    showNext: false,
    showSubmit: false
  },
  bHierarchyProj: {
    iconNumber: 11,
    nextIconNumber: 12,
    dropdownView: 'single',
    isCompleted: false,
    showNext: false,
    showSubmit: false
  },
  serviceType: {
    iconNumber: 12,
    nextIconNumber: 13,
    dropdownView: 'multiple',
    isCompleted: false,
    showNext: false,
    showSubmit: false
  },
  charge: {
    iconNumber: 13,
    nextIconNumber: null,
    dropdownView: 'multiple',
    isCompleted: false,
    showNext: false,
    showSubmit: false
  }
};
