import { BrowserAuthError, InteractionRequiredAuthError, InteractionStatus } from '@azure/msal-browser';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useEffect, useLayoutEffect, useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import '../node_modules/@syncfusion/ej2-base/styles/material.css';
import '../node_modules/@syncfusion/ej2-buttons/styles/material.css';
import '../node_modules/@syncfusion/ej2-calendars/styles/material.css';
import '../node_modules/@syncfusion/ej2-dropdowns/styles/material.css';
import '../node_modules/@syncfusion/ej2-inputs/styles/material.css';
import '../node_modules/@syncfusion/ej2-navigations/styles/material.css';
import '../node_modules/@syncfusion/ej2-popups/styles/material.css';
import '../node_modules/@syncfusion/ej2-splitbuttons/styles/material.css';
import '../node_modules/@syncfusion/ej2-notifications/styles/material.css';
import '../node_modules/@syncfusion/ej2-react-grids/styles/material.css';
import { loginApiRequest } from './authConfig';
import Preloader from './components/common/Preloader';
import AdminPage from './pages/AdminPanel/AdminPage';
import Charges from './pages/AdminPanel/Charges';
import ProjectCertificate from './pages/AdminPanel/ProjectCertificate';
import ProjectMappings from './pages/AdminPanel/ProjectMappings';
import QuotedMappings from './pages/AdminPanel/QuotedMappings';
import ServiceAgreementCertificate from './pages/AdminPanel/ServiceAgreementCertificate';
import ServiceMappings from './pages/AdminPanel/ServiceMappings';
import HomePage from './pages/Home';
import Navbar from './components/Navbar/Navbar';
import { loginRequest } from './auth/azureAD/authConfig';
import useAuth from './hooks/useAuth';
import { getUserInfo } from './services/users';

function App() {
  const { auth, setAuth } = useAuth();
  const { instance, accounts, inProgress } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const autoLogin = () => {
    if (!isAuthenticated && inProgress === InteractionStatus.None && accounts.length === 0) {
      instance
        .acquireTokenSilent(loginRequest)
        .then()
        .catch((error) => {
          if (error instanceof InteractionRequiredAuthError || error instanceof BrowserAuthError) {
            return instance
              .acquireTokenRedirect(loginRequest)
              .then()
              .catch((e) => {
                console.error(e);
              });
          }
          console.error(error);
        });
    }

    if (isAuthenticated && inProgress === InteractionStatus.None && !auth?.email) {
      getUserInfo().then((response) => {
        if (!('error' in response) && response) {
          setAuth(response);
          setIsLoading(false);
        }
      });
    }
  };

  useLayoutEffect(() => {
    autoLogin();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inProgress, isLoading]);

  if (isAuthenticated && !isLoading) {
    return (
      <div className="App">
        {/* <Navbar /> */}
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/charges" element={<Charges />} />
          <Route path="/ServiceCertificate" element={<ServiceAgreementCertificate />} />
          <Route path="/ProjectCertificate" element={<ProjectCertificate />} />
          <Route path="/ServiceMapping" element={<ServiceMappings />} />
          <Route path="/ProjectMapping" element={<ProjectMappings />} />
          <Route path="/QuotedMapping" element={<QuotedMappings />} />
          <Route path="/adminPage" element={<AdminPage />} />
        </Routes>
      </div>
    );
  }
  // preloader
  return <Preloader />;
}

export default App;
